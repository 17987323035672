import React from "react";
import { Link } from "@racepointenergy/gatsby-theme-sitespark/src/components/Html";
import Layout from "components/Layout";

export default function NotFound404() {
  return (
    <Layout title="Not found">
      <h2>
        We couldn't find the page you were looking for. This is either because:
      </h2>
      <ul>
        <li>
          There is an error in the URL entered into your web browser. Please
          check the URL and try again.
        </li>
        <li>The page you are looking for has been moved or deleted.</li>
      </ul>
      <p>
        You can return to our homepage by clicking <Link to="/">here</Link>.
      </p>
    </Layout>
  );
}
